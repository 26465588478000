<template>
  <div>

    <section class="tf-explore tf-section bd-collectionpage-activitytab" style="padding-top: 50px;">
      <div class="themesflat-container">
		<!-- mobile options -->
		<div class="bd-mobile" style="margin-top: 00px;">
			<h1 class="center">Activities</h1>
			<div class="seclect-box style-2 box-right" style="width: 100%;padding-right: 20px;">
						<FilterEvent :filters="filters.activities" :reload_function="'reload_activity'"></FilterEvent>
					</div>
		</div>
		
		<!-- mobile filters -->
		<div class="row bd-mobile">
			<div class="col-xl-12 col-lg-12 col-md-12">
				<div class="badge bd-tag active" style="display: block; margin-top: 30px; font-weight: 500;" @click="toggle_mobile_display_filters()">
					<i class="fas fa-filter" style="margin-right: 10px;"></i>
					Filters
				</div>
			</div>
		</div>
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-12 bd-activitiespage-filters" :class="{display: mobile_display_filters === true}">
            <div id="side-bar" class="side-bar style-3">
              <div class="widget widget-category mgbt-24">
                <div class="title-wg-category boder-bt">
                  <h4 class="title-widget style-2">Price</h4>
                </div>
                <div class="content-wg-category">
                  <form action="#">
                    <div class="bd-slider-container">
                      <Slider v-model="filters.activities.price.value" :min="filters.activities.price.min" :max="filters.activities.price.max" :step=0.01 :merge="filters.activities.price.max / 5" :format="format_filter_price" @update="reload_activity()"></Slider>
                    </div>
                  </form>
                </div>
              </div>
              <div class="widget widget-category mgbt-24">
                <div class="title-wg-category boder-bt">
                  <h4 class="title-widget style-2">Diamonds</h4>
                </div>
                <div class="content-wg-category">
                  <form action="#">
                    <FilterDiamonds :filters="filters.activities" :reload_function="'reload_activity'"></FilterDiamonds>
                  </form>
                </div>
              </div>
              <div class="widget mgbt-24">
                <div class="title-wg-category boder-bt">
                  <h4 class="title-widget style-2">Collection</h4>
                </div>
                <FilterCollection :filters="filters.activities" :reload_function="'reload_activity'"></FilterCollection>
              </div>
            </div>
          </div>
          <div class="col-xl-9 col-lg-9 col-md-12 bd-collectionpage-activitytab-activities bd-activitiespage-activities-zone">
			<div class="bd-desktop">
				<div style="display: inline-flex;width: 100%; margin-bottom: 5%;">
					<div class="seclect-box style-2 box-left" style="width: 100%;">
						<h1>Activities</h1>
					</div>
					<div class="seclect-box style-2 box-right" style="width: 100%;padding-right: 20px;">
						<FilterEvent :filters="filters.activities" :reload_function="'reload_activity'"></FilterEvent>
					</div>
					<div class="seclect-box style-2 box-right">
						<FilterDate :filters="filters.activities" :reload_function="'reload_activity'"></FilterDate>
					</div>
				</div>
			</div>
            <div class="row bd-collectionpage-activity-titles bd-desktop">
              <div class="col-md-2">
                Activity
              </div>
              <div class="col-md-3">
                Item
              </div>
              <div class="col-md-1">
                Price
              </div>
              <div class="col-md-2">
                Ranking
              </div>
              <div class="col-md-2">
                From/To
              </div>
              <div class="col-md-2">
                Time
              </div>
            </div>
            <h2 v-if="activities.length === 0" style="text-align:center"> No result found </h2>
            <div id="activity-box">
              <ListActivity :activity="activity" :all_activity="true" v-for="(activity, key) in activities" :key="key"></ListActivity>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import ListActivity from '@/components/ListActivity.vue';
import FilterEvent from '@/components/Filters/FilterEvent.vue';
import FilterCollection from '@/components/Filters/FilterCollection.vue';
import FilterDate from '@/components/Filters/FilterDate.vue';
import FilterDiamonds from '@/components/Filters/FilterDiamonds.vue';
import Slider from '@vueform/slider/dist/slider.vue2.js';
import axios from 'axios';

let config_axios = {
  headers: {
    'Content-Type': 'application/json;',
  }
}

export default {
  name: 'Activity',
  components: {

    ListActivity,
    FilterEvent,
    FilterCollection,
    FilterDate,
    Slider,
    FilterDiamonds,

  },
  data: function () {

    return {

      filters: {
        activities: {
          type: {
            Listing: true,
            Offers: true,
            Sales: true,
          },
          price : {
            value: [0, 500],
            min: 0,
            max: 500,
          },
          diamonds: {
            0: false,
            1: false,
            2: false,
          },
          collection : {

          },
          date : '7_last_days',
          number_per_page: 0,
        }
      },
      activities : {},
		mobile_display_filters: false,

      route_server: process.env.VUE_APP_ROOT_API,
      route_bo: process.env.VUE_APP_ROOT_BO,

    }
  },
  props: {

  },
  computed: {

  },
  methods: {

    format_filter_price: function (value) {
      return '◎ '+Math.round((value + Number.EPSILON) * 100) / 100;
    },

    reload_activity : function (scroll = false, initialize = false){

      var $this = this

      if (scroll === false)
        $this.filters['activities'].number_per_page = 0;

      if(initialize === true)
        $this.filters['initialize'] = true;

      axios.post(process.env.VUE_APP_ROOT_API+'/marketplace/activity/reload_activity',$this.filters, config_axios).then(function(elements) {

        if(scroll === false)
          $this.activities = elements.data.activity;
        else
          $this.activities = $this.activities.concat(elements.data.activity);

        if(initialize === true) {

          for (const [key, value] of Object.entries(elements.data.filters['activities'])) {

            if(key !== 'collection')
              $this.filters['activities'][key] = value;

          }

          $this.filters['initialize'] = false;
        }

        $this.$forceUpdate();
      });

    },
	toggle_mobile_display_filters: function() {

		if(this.mobile_display_filters === true)
			this.mobile_display_filters = false;
		else
			this.mobile_display_filters = true;
	},
    scroll () {
      window.onscroll = () => {

        var element = document.getElementById('activity-box');

        let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight >= element.offsetHeight

        if (bottomOfWindow && this.activities.length > this.filters['activities'].number_per_page) {
          this.filters['activities'].number_per_page += 15;
          this.reload_activity(true);
        }
      }
    },

  },
  created: function() {

    this.reload_activity(false, true)

  },
  mounted () {
  window.scrollTo(0, 0)
    this.scroll();
  },
}
</script>

<style src="@vueform/slider/themes/default.css"></style>