<template>

  <div id="collection_filters_sales" class="dropdown bd-dropdown" style="z-index: auto;">
    <a class="btn-selector nolink" style="width: 250px;">Last 7 days</a>
    <ul style="display: none;" class="">
      <li @click="filters.date = '7_last_days';$parent[reload_function]()">
        <span>Last 7 days</span>
      </li>
      <li @click="filters.date = '30_last_days';$parent[reload_function]()">
        <span>Last 30 days</span>
      </li>
      <li @click="filters.date = '90_last_days';$parent[reload_function]()">
        <span>Last 90 days</span>
      </li>
      <li @click="filters.date = 'last_year';$parent[reload_function]()">
        <span>Last year</span>
      </li>
      <li @click="filters.date = 'all_time';$parent[reload_function]()">
        <span>All time</span>
      </li>
    </ul>
  </div>

</template>

<script>
export default {
  name: "FilterDate",
  props : {

    filters : {},
    reload_function : {},

  }
}
</script>

<style scoped>

</style>