<template>

  <div class="content-wg-category">
    <form action="#">
      <div class="badge bd-tag" style="min-width: 100px;margin: 6px 6px;" v-for="(type, key) in filters.type" :key="key" :class="{active: type}" @click="filters.type[key] = !type;$parent[reload_function]()">
        {{ key }}
      </div>
    </form>
  </div>

</template>

<script>
export default {
  name: "FilterEvent",
  props : {

    filters : {},
    reload_function : {},

  }
}
</script>

<style scoped>

</style>